<template>
  <div ref="canvasContainer" style="width: 100%; height: 100%;"></div>
</template>
  
<script>
import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { RGBELoader } from "three/examples/jsm/loaders/RGBELoader";
import { VueThreejs } from "vue-threejs";
import {mapMutations,mapState} from 'vuex'
// import { AnimationMixer } from 'three/examples/jsm/animation/AnimationMixer'
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
export default {
  components: {
    VueThreejs,
    time:null,
  },
  mounted() {
    this.init3DScene();
  },
  computed:{
    ...mapState(['index'])
  },
  methods: {
...mapMutations(['MGetIndex1','MGetIndex']),
    async init3DScene() {
      let isall = false;

      const container = this.$refs.canvasContainer;

      // 控制动画是否转动
      let isAnimating = true;

      // 创建场景
      const scene = new THREE.Scene();
      // scene.background = new THREE.Color(0xf0f0f0) // 设置背景色

      // 创建相机
      const camera = new THREE.PerspectiveCamera(
        12,
        container.clientWidth / container.clientHeight,
        0.1,
        1000
      );
      // 设置相机位置
      camera.position.set(0, 7, 21); // 设置相机在 (0, 2, 8) 的位置
      camera.lookAt(0.3, 1.1, 0); // 设置相机的视点位置

      // 添加环境光
      // const ambientLight = new THREE.AmbientLight(0xffffff, 0.5)
      // scene.add(ambientLight)

      // 创建渲染器
      const renderer = new THREE.WebGLRenderer({ antialias: true });
      renderer.setSize(container.clientWidth, container.clientHeight);
      container.appendChild(renderer.domElement);

      // 加载HDR环境光贴图
      const rgbeLoader = new RGBELoader();

      const hdrTexture = await new Promise((resolve, reject) => {
        rgbeLoader.load(
          "https://gp-hk-dev.oss-accelerate.aliyuncs.com/e621e82a-6932-4353-b3b0-8149cc3f7e1dglb/dancing_hall21_2k.hdr",
          resolve,
          undefined,
          reject
        );
      });

      const pmremGenerator = new THREE.PMREMGenerator(renderer);
      pmremGenerator.compileEquirectangularShader();

      const hdrCubeRenderTarget = pmremGenerator.fromEquirectangular(
        hdrTexture
      );
      const hdrCubeMap = hdrCubeRenderTarget.texture;

      // scene.background = hdrCubeMap // 将HDR环境贴图应用为场景背景
      scene.environment = hdrCubeMap; // 将HDR环境贴图应用于场景光照
      // 创建GLTF加载器
      const gltfLoader = new GLTFLoader();
      const models = []; // 存储加载的模型
      let model1;
      let model2;
      let model2s;
      let model3;
      let model3s;
      let model4;
      let model5;
      let model11;
      let model11s;
      let model12;
      let model12s;
      let model13;
      let model13s;
      let model14;
      let model14s;
      let model15;
      let model15s;
      let model16;
      let model16s;
      let model17;
      let model17s;
      let model18;
      let model18s;
      let model19;
      let model19s;
      let model20;
      let model20s;

      // 加载GLB模型 -- 底图
      gltfLoader.load(
        "https://gp-hk-dev.oss-accelerate.aliyuncs.com/e621e82a-6932-4353-b3b0-8149cc3f7e1dglb/scene.glb",
        gltf => {
          model1 = gltf.scene;
          scene.add(model1);
          models.push(model1);
        }
      );
      // 加载GLB模型 -- 底图
      gltfLoader.load(
        "https://gp-hk-dev.oss-accelerate.aliyuncs.com/model/glb/b12_1697093162515.glb",
        gltf => {
          model5 = gltf.scene;
          scene.add(model5);
          models.push(model5);
        }
      );
      // 风车
      gltfLoader.load(
        "https://gp-hk-dev.oss-accelerate.aliyuncs.com/e621e82a-6932-4353-b3b0-8149cc3f7e1dglb/windmill.glb",
        gltf => {
          model2 = gltf.scene;
          scene.add(model2);
          models.push(model2);
          model2.name = "风车";

          // 创建动画混合器
          const mixer = new THREE.AnimationMixer(model2);

          // 获取所有动画片段
          const clips = gltf.animations;

          // 将动画片段添加到混合器
          clips.forEach(clip => {
            mixer.clipAction(clip).play();
          });

          // 渲染循环
          const animate = () => {
            requestAnimationFrame(animate);
            mixer.update(0.02); // 更新动画
            renderer.render(scene, camera);
          };

          animate();
        }
      );
      // 光伏
      gltfLoader.load(
        "https://gp-hk-dev.oss-accelerate.aliyuncs.com/e621e82a-6932-4353-b3b0-8149cc3f7e1dglb/photovoltaic.glb",
        gltf => {
          model3 = gltf.scene;
          scene.add(model3);
          models.push(model3);
          model3.name = "太阳能";
        }
      );

      // 车
      gltfLoader.load(
        "https://gp-hk-dev.oss-accelerate.aliyuncs.com/e621e82a-6932-4353-b3b0-8149cc3f7e1dglb/car.glb",
        gltf => {
          model4 = gltf.scene;
          scene.add(model4);
          models.push(model4);

          // 创建动画混合器
          const mixer = new THREE.AnimationMixer(model4);

          // 获取所有动画片段
          const clips = gltf.animations;

          // 将动画片段添加到混合器
          clips.forEach(clip => {
            mixer.clipAction(clip).play();
          });

          // 渲染循环
          const animate = () => {
            requestAnimationFrame(animate);
            mixer.update(0.02); // 更新动画
            renderer.render(scene, camera);
          };

          animate();
        }
      );
      // 楼栋
      gltfLoader.load(
        "https://gp-hk-dev.oss-accelerate.aliyuncs.com/9b0ecf45-810a-497a-a4c2-4988c1b25c8e1L.glb",
        gltf => {
          model11 = gltf.scene;
          scene.add(model11);
          models.push(model11);
          model11.name = "三号楼";
        }
      );
      gltfLoader.load(
        "https://gp-hk-dev.oss-accelerate.aliyuncs.com/e621e82a-6932-4353-b3b0-8149cc3f7e1dglb/2L.glb",
        gltf => {
          model12 = gltf.scene;
          scene.add(model12);
          models.push(model12);
          model12.name = "四号楼";
        }
      );
      gltfLoader.load(
        "https://gp-hk-dev.oss-accelerate.aliyuncs.com/e621e82a-6932-4353-b3b0-8149cc3f7e1dglb/3L.glb",
        gltf => {
          model13 = gltf.scene;
          scene.add(model13);
          models.push(model13);
          model13.name = "五号楼";
        }
      );
      gltfLoader.load(
        "https://gp-hk-dev.oss-accelerate.aliyuncs.com/e621e82a-6932-4353-b3b0-8149cc3f7e1dglb/4L.glb",
        gltf => {
          model14 = gltf.scene;
          scene.add(model14);
          models.push(model14);
          model14.name = "六号楼";
        }
      );
      gltfLoader.load(
        "https://gp-hk-dev.oss-accelerate.aliyuncs.com/e621e82a-6932-4353-b3b0-8149cc3f7e1dglb/5L.glb",
        gltf => {
          model15 = gltf.scene;
          scene.add(model15);
          models.push(model15);
          model15.name = "七号楼";
        }
      );
      gltfLoader.load(
        "https://gp-hk-dev.oss-accelerate.aliyuncs.com/e621e82a-6932-4353-b3b0-8149cc3f7e1dglb/6L.glb",
        gltf => {
          model16 = gltf.scene;
          scene.add(model16);
          models.push(model16);
          model16.name = "八号楼";
        }
      );
      gltfLoader.load(
        "https://gp-hk-dev.oss-accelerate.aliyuncs.com/model/glb/111111_1697011710508.glb",
        gltf => {
          model17 = gltf.scene;
          scene.add(model17);
          models.push(model17);
          model17.name = "九号楼";
        }
      );
      gltfLoader.load(
        "https://gp-hk-dev.oss-accelerate.aliyuncs.com/e621e82a-6932-4353-b3b0-8149cc3f7e1dglb/8L.glb",
        gltf => {
          model18 = gltf.scene;
          scene.add(model18);
          models.push(model18);
          model18.name = "十号楼";
        }
      );
      gltfLoader.load(
        "https://gp-hk-dev.oss-accelerate.aliyuncs.com/e621e82a-6932-4353-b3b0-8149cc3f7e1dglb/9L.glb",
        gltf => {
          model19 = gltf.scene;
          scene.add(model19);
          models.push(model19);
          model19.name = "十一号楼";
        }
      );
      gltfLoader.load(
        "https://gp-hk-dev.oss-accelerate.aliyuncs.com/e621e82a-6932-4353-b3b0-8149cc3f7e1dglb/10L.glb",
        gltf => {
          model20 = gltf.scene;
          scene.add(model20);
          models.push(model20);
          model20.name = "十二号楼";
        }
      );

      //加载高亮模型
      gltfLoader.load(
        "https://gp-hk-dev.oss-accelerate.aliyuncs.com/model/glb/scene_1696992324116.glb",
        gltf => {
          model11s = gltf.scene;
        }
      );
      gltfLoader.load(
        "https://gp-hk-dev.oss-accelerate.aliyuncs.com/model/glb/2L%281%29_1697002393024.glb",
        gltf => {
          model12s = gltf.scene;
        }
      );
      gltfLoader.load(
        "https://gp-hk-dev.oss-accelerate.aliyuncs.com/model/glb/3L%281%29_1697002496493.glb",
        gltf => {
          model13s = gltf.scene;
        }
      );
      gltfLoader.load(
        "https://gp-hk-dev.oss-accelerate.aliyuncs.com/model/glb/4L%281%29_1697002551660.glb",
        gltf => {
          model14s = gltf.scene;
        }
      );
      gltfLoader.load(
        "https://gp-hk-dev.oss-accelerate.aliyuncs.com/model/glb/5L%281%29_1697002599280.glb",
        gltf => {
          model15s = gltf.scene;
        }
      );
      gltfLoader.load(
        "https://gp-hk-dev.oss-accelerate.aliyuncs.com/model/glb/6L%281%29_1697002628569.glb",
        gltf => {
          model16s = gltf.scene;
        }
      );
      gltfLoader.load(
        "https://gp-hk-dev.oss-accelerate.aliyuncs.com/model/glb/1111s_1697011883557.glb",
        gltf => {
          model17s = gltf.scene;
        }
      );
      gltfLoader.load(
        "https://gp-hk-dev.oss-accelerate.aliyuncs.com/model/glb/8L%281%29_1697002684676.glb",
        gltf => {
          model18s = gltf.scene;
        }
      );
      gltfLoader.load(
        "https://gp-hk-dev.oss-accelerate.aliyuncs.com/model/glb/9L%281%29_1697002719811.glb",
        gltf => {
          model19s = gltf.scene;
        }
      );
      gltfLoader.load(
        "https://gp-hk-dev.oss-accelerate.aliyuncs.com/model/glb/10L%281%29_1697002742840.glb",
        gltf => {
          model20s = gltf.scene;
        }
      );
      gltfLoader.load(
        "https://gp-hk-dev.oss-accelerate.aliyuncs.com/model/glb/photovoltaic%281%29_1697002830919.glb",
        gltf => {
          model3s = gltf.scene;
        }
      );
      gltfLoader.load(
        "https://gp-hk-dev.oss-accelerate.aliyuncs.com/model/glb/windmill%281%29_1697002846472.glb",
        gltf => {
          model2s = gltf.scene;
          // 创建动画混合器
          const mixer = new THREE.AnimationMixer(model2s);
          // 获取所有动画片段
          const clips = gltf.animations;
          // 将动画片段添加到混合器
          clips.forEach(clip => {
            mixer.clipAction(clip).play();
          });
          // 渲染循环
          const animate = () => {
            requestAnimationFrame(animate);
            mixer.update(0.02); // 更新动画
            renderer.render(scene, camera);
          };
          animate();
          isall = true;
        }
      );
      //轮播
      let Dongs = [
        "三号楼",
        "四号楼",
        "五号楼",
        "六号楼",
        "七号楼",
        "八号楼",
        "九号楼",
        "十号楼",
        "十一号楼",
        "十二号楼",
        "太阳能",
        "风车"
      ];

      let off = 1;
      let i = 0;
      const DongsFn = () => {
        const flag = scene.children.find(item => item.name == Dongs[i]);
        if (flag.name == "三号楼") {
          if (off == 1) {
            scene.add(model11s);
            off = 0;
          } else {
            scene.remove(model2s);
            scene.add(model2);
            scene.add(model11s);
          }
        } else if (flag.name == "四号楼") {
          scene.remove(model11s);
          scene.add(model12s);
        } else if (flag.name == "五号楼") {
          scene.remove(model12s);
          scene.add(model13s);
        } else if (flag.name == "六号楼") {
          scene.remove(model13s);
          scene.add(model14s);
        } else if (flag.name == "七号楼") {
          scene.remove(model14s);
          scene.add(model15s);
        } else if (flag.name == "八号楼") {
          scene.remove(model15s);
          scene.add(model16s);
        } else if (flag.name == "九号楼") {
          scene.remove(model16s);
          scene.add(model17s);
        } else if (flag.name == "十号楼") {
          scene.remove(model17s);
          scene.add(model18s);
        } else if (flag.name == "十一号楼") {
          scene.remove(model18s);
          scene.add(model19s);
        } else if (flag.name == "十二号楼") {
          scene.remove(model19s);
          scene.add(model20s);
        } else if (flag.name == "太阳能") {
          scene.remove(model20s);
          scene.add(model3s);
        } else if (flag.name == "风车") {
          scene.remove(model3s);
          scene.remove(flag);
          scene.add(model2s);
        }
        if (i <= 5) {
          this.$emit("clickModel", { data: Dongs[i], type: 1 });
        } else {
          this.$emit("clickModel", { data: Dongs[i], type: 2 });
        }
        i++;
        if (i == 12) {
          i = 0;
        }
      };

      this.time=setInterval(()=>{
        DongsFn()
      }, 3500);
      let cameraRotation = 0; // 摄像机旋转角度
      let targetRotation = Math.PI / 10; // 目标旋转角度
      let rotateClockwise = true; // 是否顺时针旋转
      function animate() {
        // 更新摄像机位置和朝向
        const x = Math.sin(cameraRotation) * 24;
        const z = Math.cos(cameraRotation) * 24;
        camera.position.set(x, 8, z);
        camera.lookAt(0.3, 1, 0);
        // 根据旋转方向进行增减角度
        if (rotateClockwise) {
          cameraRotation += 0.001; // 顺时针旋转
        } else {
          cameraRotation -= 0.001; // 逆时针旋转
        }
        // 判断是否达到目标角度，并改变旋转方向
        if (cameraRotation >= targetRotation) {
          cameraRotation = targetRotation;
          rotateClockwise = !rotateClockwise;
        } else if (cameraRotation <= -targetRotation) {
          cameraRotation = -targetRotation;
          rotateClockwise = !rotateClockwise;
        }

        // 渲染场景
        renderer.render(scene, camera);
        // 循环调用 animate() 函数
        requestAnimationFrame(animate);
      }
      // 开始执行动画
      animate();
    },
    onMouseClick(event) {
      console.log(event);
      return;
    }
  },
  beforeDestroy(){
     clearInterval(this.time)
  }
};
</script>
  
<style>
/* Add any necessary styling for the component */
</style>
  