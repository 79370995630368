import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import './assets/font/font.css'

import { getCurrentDateTime, animateNumber } from './utils';

Vue.prototype.$utils = {
  getCurrentDateTime
}
Vue.directive('animate-number', {
  bind: function (el, binding) {
    animateNumber(el, binding.value);
  },
  update: function (el, binding) {
    // 在数据更新时调用
    animateNumber(el, binding.value);
  }
});
// import echarts from 'echarts' // 引入echarts
// Vue.prototype.$echarts = echarts

// import VueiClient from '@supermap/vue-iclient-mapboxgl';
// Vue.use(VueiClient, { cesiumPath: './Build/Cesium/Cesium.js' }); // 此 url 路径相对于根目录

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
