<template>
  <div class="Liangjiang-New-Area">
    <HomeHeader class="header" :name="'重庆市两江数字经济产业园'" />
    <LiangJiangScene @clickModel="clickModel" />
    <DataPopup v-if="isShow" :data="generatedData" :index='index' :name="name" :top="top" :left="left" />
    <!-- <div class="back" @click="() => $router.push({ path: '/', query: { index: 2 } })">
      <img src="https://gp-hk-dev.oss-accelerate.aliyuncs.com/b8821b4d-723c-41f0-9384-6f9a11ef1795XTY/pack-up.svg" alt />
    </div>-->
  </div>
</template>
  
<script>
import HomeHeader from "@/components/HomeHeader";
import LiangJiangScene from "@/components/LiangJiangScene"; // 路径根据你的项目结构而定
import DataPopup from "@/components/DataPopup";

export default {
  components: {
    HomeHeader,
    LiangJiangScene,
    DataPopup
  },
  data() {
    return {
      time:null,
      isShow: false,
      index:0,
      generatedData: {
        num: 1680,
        top: "12%",
        left: "70%",
        data: [
          [2458, 73740],
          [3557, 106710],
          [1458, 43740]
        ]
      }
    };
  },
  methods: {
    // 生成随机数据
    generateData() {
      const num = Math.floor(Math.random() * (2000 - 500 + 1)) + 500;
      const data = [];

      for (let i = 0; i < 3; i++) {
        const firstNumber =
          Math.floor(Math.random() * (3000 - 1000 + 1)) + 1000;
        const secondNumber = firstNumber * 30;
        data.push([firstNumber, secondNumber]);
      }

      this.generatedData = {
        num: num,
        data: data
      };
    },
    clickModel(name) {
      this.isShow = false;
      this.name = name.data.replace(/[_0-9]/g, "");
      if (
        name.data.indexOf("风车") !== -1 ||
        name.data.indexOf("太阳能") !== -1 ||
        name.data.indexOf("楼") !== -1
      ) {
        this.$nextTick(() => {
          this.isShow = true;
        });
      }
      if (name.type == 1) {
        this.top = "12%";
        this.left = "70%";
      } else {
        this.top = "12%";
        this.left = "10%";
      }
      this.index++
      if(this.index>=12){
        this.index=0
      }
    }
  },

  beforeDestroy(){
    if(this.$route.path=='/page2'){
       clearInterval(this.time)
    }
  }
};
</script>
<style lang="scss" scoped>
.Liangjiang-New-Area {
  width: 100%;
  height: 995.55px;
  cursor: pointer;

  .header {
    background: none;
    position: absolute;
    width: 100%;
  }

  .back {
    position: fixed;
    top: 0;
    transform: rotate(180deg);
  }
}
</style>