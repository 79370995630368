<template>
  <div class="recommendPage">
    <!-- <Page1 v-if="type == 1" />
    <Page3 v-else-if="type == 2" />
    <Page2 v-else-if="type == 3" />
    <Page4 v-else-if="type == 4" />
    <LiangjiangNewArea v-else></LiangjiangNewArea> -->
    <router-view></router-view>
    <!-- 切换按钮 -->
    <div class="side-switching" :class="[isUnfold ? '' : 'unfold']">
      <div>
        <div class="btn" @click="() => (isUnfold = !isUnfold)">
          <img
            src="https://gp-hk-dev.oss-accelerate.aliyuncs.com/b8821b4d-723c-41f0-9384-6f9a11ef1795XTY/pack-up.svg"
            alt
          />
        </div>
        <div class="side-main" v-show="isUnfold">
          <div @click="switchSlide(1)">
            <img
              src="https://gp-hk-dev.oss-accelerate.aliyuncs.com/b8821b4d-723c-41f0-9384-6f9a11ef1795XTY/sideSwitch/btn1.png"
              alt
            />
            <p>星火链网 | 赋能双碳</p>
          </div>
          <div @click="switchSlide(2)">
            <img
              src="https://gp-hk-dev.oss-accelerate.aliyuncs.com/b8821b4d-723c-41f0-9384-6f9a11ef1795XTY/sideSwitch/btn2.png"
              alt
            />
            <p>碳数据 | 全景大屏</p>
          </div>
          <div @click="switchSlide(3)">
            <img
              src="https://gp-hk-dev.oss-accelerate.aliyuncs.com/b8821b4d-723c-41f0-9384-6f9a11ef1795XTY/sideSwitch/btn3.png"
              alt
            />
            <p>园区碳排 | 分析看板</p>
          </div>
          <div @click="switchSlide(5)">
            <img src="@/assets/sideSwitch/estate.svg" alt />
            <p>两江新区 | 数字产业园</p>
          </div>
          <div @click="switchSlide(4)">
            <img
              src="https://gp-hk-dev.oss-accelerate.aliyuncs.com/b8821b4d-723c-41f0-9384-6f9a11ef1795XTY/sideSwitch/btn4.png"
              alt
            />
            <p>汽车制造 | 碳足迹核算</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// 引入插件
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";

import Page1 from "./page1.vue";
import Page2 from "./page2.vue";
import Page3 from "./page3.vue";
import Page4 from "./page4.vue";
import LiangjiangNewArea from "./LiangjiangNewArea";
import { mapMutations } from "vuex";
export default {
  components: {
    swiper,
    swiperSlide,
    Page1,
    Page2,
    Page3,
    Page4,
    LiangjiangNewArea,
  },
  data() {
    return {
      width: 0,
      type: 1,
      swiperOption: {
        loop: true,
        speed: 2000,
        easing: "ease-in-out",
        autoplay: false, // 开发时设置停止轮播动画
        initialSlide: 0, // 当前展示的下标 开发用
        effect: "fade", // 使用渐入渐出效果
        fadeEffect: {
          crossFade: true, // 渐入渐出交叉淡化
        },
        // autoplay: {
        //   delay: 6000,
        //   stopOnLastSlide: false,
        //   disableOnInteraction: false
        // },
      },
      // 选项是否展开
      isUnfold: true,
      lastClickTime: 0,
      timer: null,
    };
  },
  created() {
    window.addEventListener("resize", () => {
      this.width = window.innerWidth;
      console.log(this.width);
    });

    if (this.$route.query.index) {
      console.log(this.swiperOption);
      this.swiperOption.initialSlide = Number(this.$route.query.index);
    }
  },
  mounted() {
    // this.handleClick();
  },
  methods: {
    ...mapMutations(["GetType"]),
    // 停止点击按钮一段时间就收起按钮
    // handleClick() {
    //   this.lastClickTime = Date.now();
    //   this.isUnfold = true;

    //   if (this.timer) {
    //     clearTimeout(this.timer);
    //   }

    //   this.timer = setTimeout(() => {
    //     this.isUnfold = false;
    //   }, 10000);
    // },
    // 切换轮播
    switchSlide(index) {
      // // 获取 Swiper 实例
      // const swiper = this.$refs.mySwiper.swiper;
      // // 切换到指定的 slide
      // swiper.slideTo(index);
      // if (index == 4) {
      //   this.$refs.page4.startVideo();
      // } else {
      //   this.$refs.page4?.pauseVideo();
      // }

      // // 点击时重置按钮时间
      // this.handleClick();
      if (index == 1) {
        this.$router.push("/page1").catch((err) => {});
      } else if (index == 2) {
        this.$router.push("/page3").catch((err) => {});
      } else if (index == 3) {
        window.location.href = "http://yuzhong.etantong.com/page2";
        // this.$router.push("/page2").catch((err) => {});
      } else if (index == 4) {
        this.$router.push("/page4").catch((err) => {});
      } else if (index == 5) {
        this.$router.push("/LiangjiangNewArea").catch((err) => {});
      }
    },
  },
};
</script>
<style scoped  lang="scss">
.recommendPage {
  width: 3360px;
  height: 995.55px;
  // background: pink;
  position: relative;
}

.side-switching {
  position: absolute;
  left: 0;
  width: 100%;
  height: 79px;
  bottom: 0px;
  z-index: 10;
  > div {
    display: flex;
    height: 100%;
  }

  .btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 100%;
    background: linear-gradient(
      to bottom,
      rgba(#00e6ff, 0),
      rgba(#05dee0, 0.16) 50%,
      rgba(#8affff, 0.5) 75%,
      rgba(#00feff, 0.6) 100%
    );
    cursor: pointer;

    img {
      transform: rotate(180deg);
    }
  }

  .side-main {
    flex: 1;
    height: 100%;
    display: flex;
    align-items: center;
    background: linear-gradient(
      90deg,
      rgba(18, 67, 57, 0.8) 0%,
      rgba(20, 73, 66, 0) 100%
    );

    > div {
      width: 400px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      margin-left: 60px;

      img {
        width: 80px;
        height: 80px;
      }

      p {
        font-size: 28px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #d6ffff;
        line-height: 20px;
        letter-spacing: 1px;
        text-align: center;
        margin: 0;
      }
    }
  }
}
.side-switching1 {
  position: absolute;
  left: 0;
  width: 100%;
  height: 130px;
  bottom: 0px;
  z-index: 10;
  > div {
    display: flex;
    height: 100%;
  }

  .btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 100%;
    background: linear-gradient(
      to bottom,
      rgba(#00e6ff, 0),
      rgba(#05dee0, 0.16) 50%,
      rgba(#8affff, 0.5) 75%,
      rgba(#00feff, 0.6) 100%
    );
    cursor: pointer;

    img {
      transform: rotate(180deg);
    }
  }

  .side-main {
    flex: 1;
    height: 100%;
    display: flex;
    align-items: center;
    background: linear-gradient(
      90deg,
      rgba(18, 67, 57, 0.8) 0%,
      rgba(20, 73, 66, 0) 100%
    );

    > div {
      min-width: 300px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      margin-left: 60px;

      img {
        width: 88px;
        height: 88px;
      }

      p {
        font-size: 28px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #d6ffff;
        line-height: 20px;
        letter-spacing: 1px;
        text-align: center;
        margin: 0;
      }
    }
  }
}

.unfold {
  transition: all 0.2s ease-in-out;
  width: 24px;

  .btn img {
    transition: all 0.2s ease-in-out;
    transform: rotate(0deg);
  }

  .side-main > div {
    transition: all 0.2s ease-in-out;
    width: 0;
  }
}

.recommendPage .swiper-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.swiper-slide {
  img {
    width: 100%;
  }
}
</style>

