import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import LiangjiangNewArea from '../views/LiangjiangNewArea.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    redirect: '/page1',
    children: [
      {
        path: '/LiangjiangNewArea',
        name: 'LiangjiangNewArea',
        component: LiangjiangNewArea
      }, {
        path: '/page1',
        name: 'page1',
        component: () => import('@/views/page1.vue'),
      }, {
        path: '/page2',
        name: 'page2',
        component: () => import('@/views/page2.vue'),

      }, {
        path: '/page3',
        name: 'page3',
        component: () => import('@/views/page3.vue'),
      }, {
        path: '/page4',
        name: 'page4',
        component: () => import('@/views/page4.vue'),
      }
    ],
    component: HomeView
  },
  // {
  //   path: '/LiangjiangNewArea',
  //   name: 'LiangjiangNewArea',
  //   component: LiangjiangNewArea
  // }, {
  //   path: '/page1',
  //   name: 'page1',
  //   component: () => import('@/views/page1.vue'),
  // }, {
  //   path: '/page2',
  //   name: 'page2',
  //   component: () => import('@/views/page2.vue'),

  // }, {
  //   path: '/page3',
  //   name: 'page3',
  //   component: () => import('@/views/page3.vue'),

  // }, {
  //   path: '/page4',
  //   name: 'page4',
  //   component: () => import('@/views/page4.vue'),
  // }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
