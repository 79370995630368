<template>
  <div class="page2">
    <HomeHeader
      :isShowLeft="true"
      :name="'园区碳排分析看板'"
      class="homeHeader"
    />
    <div class="main">
      <div class="item">
        <img
          src="https://gp-hk-dev.oss-accelerate.aliyuncs.com/b8821b4d-723c-41f0-9384-6f9a11ef1795XTY/let-icon1.png"
          alt
        />
        <div class="left">
          <p class="name">当日碳排放量（t/CO₂）</p>
          <span class="value">{{ discharge | numberFilter }}</span>
        </div>
        <div class="line"></div>
        <div class="right">
          <div>
            当月碳排放量（t/CO₂）
            <span class="discharge">{{
              (9245821 + discharge) | numberFilter
            }}</span>
          </div>
          <div>
            当年碳排放量（t/CO₂）
            <span class="discharge">{{
              (36341722 + discharge) | numberFilter
            }}</span>
          </div>
        </div>
      </div>
      <div class="item">
        <img
          src="https://gp-hk-dev.oss-accelerate.aliyuncs.com/b8821b4d-723c-41f0-9384-6f9a11ef1795XTY/electricity.png"
          alt
        />
        <div class="left">
          <p class="name">当日用电量（kwh）</p>
          <span class="value">{{ discharge | numberFilter }}</span>
        </div>
        <div class="line"></div>
        <div class="right">
          <div>
            当月用电量（kwh）
            <span class="discharge">{{
              (9411234 + discharge) | numberFilter
            }}</span>
          </div>
          <div>
            当年用电量（kwh）
            <span class="discharge">{{
              (27411234 + discharge) | numberFilter
            }}</span>
          </div>
        </div>
      </div>
      <div class="item gas">
        <img
          src="https://gp-hk-dev.oss-accelerate.aliyuncs.com/b8821b4d-723c-41f0-9384-6f9a11ef1795XTY/gas.png"
          alt
        />
        <div class="left">
          <p class="name">当日天然气量（m³）</p>
          <span class="value">{{ discharge | numberFilter }}</span>
        </div>
        <div class="line"></div>
        <div class="right">
          <div>
            当月天然气量（m³）
            <span class="discharge">{{
              (6267371 + discharge) | numberFilter
            }}</span>
          </div>
          <div>
            当年天然气量（m³）
            <span class="discharge">{{
              (14521156 + discharge) | numberFilter
            }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="popup-box">
      <div
        v-if="active !== 0"
        class="popup-line"
        :class="`popup-line${active}`"
      ></div>
      <DataPopup
        v-if="active !== 0"
        :name="name"
        :index="index"
        :top="top"
        :left="left"
        :dischargeList="dischargeList"
      />

      <div class="garden" :style="{ opacity: active === 1 ? '1' : '' }">
        <!-- <p class="liangjiang-text">重庆市两江数字经济产业园</p> -->
        <img src="@/assets/3.png" alt />
      </div>
      <div class="garden garden2" :style="{ opacity: active === 2 ? '1' : '' }">
        <img src="@/assets/2.png" alt />
      </div>
      <div class="garden garden3" :style="{ opacity: active === 3 ? '1' : '' }">
        <img src="@/assets/1.png" alt />
      </div>

      <div class="garden garden4" :style="{ opacity: active === 4 ? '1' : '' }">
        <img src="@/assets/4.png" alt />
      </div>

      <div class="garden garden5" :style="{ opacity: active === 5 ? '1' : '' }">
        <img src="@/assets/5.png" alt />
      </div>

      <div class="garden garden6" :style="{ opacity: active === 6 ? '1' : '' }">
        <img src="@/assets/6.png" alt />
      </div>

      <img src="@/assets/3s.png" />
      <img src="@/assets/2s.png" class="img2" />
      <img src="@/assets/1s.png" class="img3" />
      <img src="@/assets/4s.png" class="img4" />
      <img src="@/assets/5s.png" class="img5" />
      <img src="@/assets/6s.png" class="img6" />
    </div>
  </div>
</template>

<script>
import HomeHeader from "@/components/HomeHeader";
import DataPopup from "@/components/DataPopup";
import { numAnimation, numberFilter } from "@/utils";
export default {
  components: { HomeHeader, DataPopup },
  data() {
    return {
      discharge: 354562,
      active: 0,
      index: 0,
      time: null,
      top: "28.3%",
      left: "59.7%",
      name: "两江新区互联网产业园",
      dischargeList: [3056, 2400, 2822, 4215, 3129, 4978],
    };
  },
  filters: {
    numberFilter,
  },
  mounted() {
    this.updateEquipment();
    this.carousel();
  },
  methods: {
    close() {
      //this.active = 0;
    },

    //轮播
    carousel() {
      let index = 1;
      this.changePopup(index);
      index++;
      setInterval(() => {
        this.changePopup(index);
        index++;
        this.active = index;
        if (index > 6) {
          index = 1;
        }
      }, 3500);
    },
    changePopup(index) {
      setTimeout(() => {
        this.active = index;
        if (index === 1) {
          this.name = "两江新区互联网产业园";
          this.top = "32.3%";
          this.left = "59.7%";
        } else if (index === 2) {
          this.name = "福安药业集团";
          this.top = "57.3%";
          this.left = "43.7%";
        } else if (index === 3) {
          this.name = "川仪分析工业园";
          this.top = "42.2%";
          this.left = "23.1%";
        } else if (index === 4) {
          this.name = "电力科学研究院建筑";
          this.top = "52%";
          this.left = "42%";
        } else if (index === 5) {
          this.name = "半导体产业园";
          this.top = "51%";
          this.left = "81%";
        } else if (index === 6) {
          this.name = "汽车高新技术园区";
          this.top = "50%";
          this.left = "73.5%";
        }
      }, 0);
    },
    // 设置设备数量动画
    updateEquipment() {
      numAnimation(
        this.discharge,
        this.getNextRandomNumber(this.discharge, this.discharge + 100) || 0,
        this,
        "discharge"
      );
      setTimeout(this.updateEquipment, 3000);
    },
    // 获取随机数
    getNextRandomNumber(min, max) {
      var randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
      // 判断下次随机数是否比当前随机数大，如果不大则递归调用函数获取新的随机数
      if (randomNumber <= this.discharge) {
        return this.getNextRandomNumber(min, max);
      }
      return randomNumber;
    },
  },
  created() {
    console.log(this.dischargeList);
    this.time = setInterval(() => {
      this.index++;
      if (this.index >= 6) {
        this.index = 0;
      }
    }, 3500);
  },
  beforeDestroy() {
    clearInterval(this.time);
  },
};
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
  transition: all 0.2s ease-in-out;
}
.homeHeader {
  position: relative;
  z-index: 9999;
}
.garden {
  transform: scale(0.7);

  position: absolute;
  left: 1299px;
  top: 55px;
  opacity: 0;
  z-index: 999;
  //&:hover {
  //opacity: 1;
  //}

  .cursor {
    position: absolute;
    left: 50%;
    top: 1%;
    margin-left: -25px;
    margin-top: -25px;
  }

  .liangjiang-text {
    position: absolute;
    left: 145px;
    top: -53px;
    font-size: 30px;
    font-weight: bold;
    color: #00fbe7;
  }
}

.garden2 {
  left: 735px;
  top: 456px;
}

.garden3 {
  left: 314px;
  top: -10px;
}

.garden4 {
  left: 1956px;
  top: 529px;
}
.garden5 {
  left: 2224px;
  top: 234px;
}
.garden6 {
  left: 2981px;
  top: 557px;
}
.popup-line {
  position: absolute;
  top: 31.5%;
  left: 52.5%;
  width: 140px;
  height: 1px;
  background: #00fbe7;
  transform: rotate(-15deg);
}

.popup-line1 {
  width: 170px;
  top: 30.5%;
  left: 55.2%;
  transform: rotate(204deg);
}

.popup-line2 {
  width: 342px;
  top: 71%;
  left: 35%;
  transform: rotate(141deg);
}

.popup-line3 {
  width: 233px;
  top: 35%;
  left: 17%;
  transform: rotate(37deg);
}

.popup-line4 {
  width: 244px;
  top: 66.2%;
  left: 54%;
  transform: rotate(47deg);
}
.popup-line5 {
  width: 165px;
  top: 46.2%;
  left: 78%;
  transform: rotate(54deg);
}
.popup-line6 {
  width: 277px;
  top: 65.2%;
  left: 85%;
  transform: rotate(47deg);
}

.page2 {
  width: 100%;
  height: 100%;
  background: url("@/assets/back.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
  position: relative;

  .popup-box {
    position: absolute;
    width: 100%;
    height: 100%;
    bottom: 0;
    > img {
      transform: scale(0.7);
      position: absolute;
      left: 1299px;
      top: 55px;
    }
    .img2 {
      left: 735px;
      top: 456px;
    }
    .img3 {
      left: 314px;
      top: -10px;
    }
    .img4 {
      left: 1956px;
      top: 529px;
    }
    .img5 {
      left: 2224px;
      top: 234px;
    }
    .img6 {
      left: 2981px;
      top: 557px;
    }
  }

  .main {
    display: flex;
    justify-content: space-between;
    padding: 0 24px;
    position: fixed;
    top: 100px;
    z-index: 9999;
    > div {
      width: 1072px;
      height: 68px;
      background: rgba(7, 116, 128, 0.7);
      display: flex;
      align-items: center;
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #d6ffff;
      margin: 0px 10px;
      img {
        width: 70px;
        height: 66px;
        margin-left: 14px;
      }

      .value {
        font-size: 24px;
        font-family: DIN-Bold, DIN;
        font-weight: bold;
        color: #00fbe7;
      }

      .left {
        margin-left: 5px;
      }

      .line {
        width: 1px;
        height: 40px;
        background: linear-gradient(
          180deg,
          rgba(237, 255, 255, 0) 0%,
          #e1ffff 50%,
          rgba(214, 255, 255, 0) 100%
        );
        opacity: 0.8;
        margin-left: 12px;
      }

      .right {
        margin-left: 40px;

        .discharge {
          font-size: 14px;
          font-family: DIN-Bold, DIN;
          font-weight: bold;
          color: #00fbe7;
        }

        > div:nth-child(1) {
          margin-bottom: 12px;
        }
      }
    }

    .gas {
      .value {
        color: #f3e141;
      }

      .right {
        .discharge {
          color: #f3e141;
        }
      }
    }
  }
}
</style>