/**
 * @param {Function} func
 * @param {number} wait
 * @param {boolean} immediate
 * @return {*}
 */
export function debounce(func, wait, immediate) {
  let timeout, args, context, timestamp, result

  const later = function () {
    // 据上一次触发时间间隔
    const last = +new Date() - timestamp

    // 上次被包装函数被调用时间间隔 last 小于设定时间间隔 wait
    if (last < wait && last > 0) {
      timeout = setTimeout(later, wait - last)
    } else {
      timeout = null
      // 如果设定为immediate===true，因为开始边界已经调用过了此处无需调用
      if (!immediate) {
        result = func.apply(context, args)
        if (!timeout) context = args = null
      }
    }
  }

  return function (...args) {
    context = this
    timestamp = +new Date()
    const callNow = immediate && !timeout
    // 如果延时不存在，重新设定延时
    if (!timeout) timeout = setTimeout(later, wait)
    if (callNow) {
      result = func.apply(context, args)
      context = args = null
    }

    return result
  }
}
/**
 * 数字过渡动画-目前版本-默认一秒之内完成过渡动画
 * @param current number 当前值
 * @param target number 目标值
 * @param _this 传递 当前实例指向 this
 * @param property string _this 和 property 是因为要把需要改变的属性传递进去，因为函数传参基础数据类型是值拷贝而不是值引用
 * @param step number 当前版本支持 1 0.1 0.01 分别对应 整数变化、一位小数、两位小数
 */

export function numAnimation(current, target, _this, property, step = 1, totalTime = 1000, duration = 20, timer = '') {
  clearInterval(timer)
  let ternialStep = 0
  if (target >= current) {
    ternialStep = (target - current) / (totalTime / duration)
  } else {
    ternialStep = (current - target) / (totalTime / duration)
  }

  if (ternialStep > 10) {
    ternialStep = parseInt(ternialStep).toString().split('')
    ternialStep[ternialStep.length - 1] = 1
    ternialStep = parseInt(ternialStep.join(''))
  } else {
    ternialStep = Math.ceil(ternialStep) || 1
  }
  if (current < target) {
    timer = setInterval(() => {
      if (step === 0.1) {
        _this[property] = (_this[property] * 1000 + (step + ternialStep) * 1000) / 1000
      } else if (step === 0.01) {
        _this[property] = (_this[property] * 1000 + (step + ternialStep) * 1000) / 1000
      } else {
        _this[property] = _this[property] + ternialStep
      }
      if (_this[property] >= target) {
        _this[property] = target
        clearInterval(timer)
      }
    }, duration)
  } else if (current > target) {
    timer = setInterval(() => {
      if (step === 0.1) {
        _this[property] = (_this[property] * 1000 - (step + ternialStep) * 1000) / 1000
      } else if (step === 0.01) {
        _this[property] = (_this[property] * 1000 - (step + ternialStep) * 1000) / 1000
      } else {
        _this[property] = _this[property] - ternialStep
      }
      if (_this[property] <= target) {
        _this[property] = target
        clearInterval(timer)
      }
    }, duration)
  }
}
// 获取当前时间和星期几
export function getCurrentDateTime() {
  const now = new Date()
  const year = now.getFullYear()
  const month = String(now.getMonth() + 1).padStart(2, '0')
  const day = String(now.getDate()).padStart(2, '0')
  const hour = String(now.getHours()).padStart(2, '0')
  const minute = String(now.getMinutes()).padStart(2, '0')
  const second = String(now.getSeconds()).padStart(2, '0')
  const dayOfWeek = now.getDay()

  const formattedDateTime = `${year}/${month}/${day} ${hour}:${minute}:${second}`
  const weekdays = ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六']
  const formattedDayOfWeek = weekdays[dayOfWeek]

  return { formattedDateTime, formattedDayOfWeek }
}

/* 数字金额逢三加， 比如 123,464.23 */
export const numberFilter = function (value, cut = 2) {
  //value为我们传进来的数据 比如  145775.422346
  //cut 为需要保留的小数位数  -1为清空小数 0为保留全部位数的小数 传入多少即为多少 不传默认保留两位小数 传进来多少就截取多少
  //数据校验
  if (parseFloat(value).toString() == 'NaN') return '0.00'
  // 将数值截取
  let num = value.toString().split('.')
  let zs = num[0]
  let xs = num[1]
  // 整数部分处理，增加,
  const intPartFormat = zs.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
  if (xs != null) {
    if (cut == 0) {
      return intPartFormat + '.' + xs
    } else if (cut == -1) {
      return intPartFormat
    } else {
      return intPartFormat + '.' + xs.substr(0, cut)
    }
  } else {
    return intPartFormat
  }
}

export function animateNumber(el, endNum) {
  let startNum = 0; // 初始数字
  let duration = 2500; // 动画持续时间（毫秒）

  let range = endNum - startNum;
  let currentNum = startNum;
  let startTime = null;

  function animate(timestamp) {
    if (!startTime) startTime = timestamp;
    let progress = timestamp - startTime;
    currentNum = Math.floor((range * progress) / duration + startNum);

    el.innerText = currentNum;

    if (progress < duration && currentNum < endNum) {
      requestAnimationFrame(animate);
    } else {
      el.innerText = endNum;
    }
  }
  requestAnimationFrame(animate);
}