<template>
  <div :class="className" :style="{ height: height, width: width }" />
</template>
  
<script>
import echarts from 'echarts'
import resize from './resize'

const animationDuration = 1000

export default {
  mixins: [resize],
  props: {
    // 图表名称
    className: {
      type: String,
      default: 'chart'
    },
    // 图表宽
    width: {
      type: String,
      default: '100%'
    },
    // 图表高
    height: {
      type: String,
      default: '240px'
    },
    // 图表数据
    chartsData: {
      type: Object,
      default: () => {
        return {
          legend: [],
          data: [
            { value: 720, name: '范围一：直接排放' },
            { value: 760, name: '范围二：间接排放' },
            { value: 520, name: '范围三：其他排放' }
          ]
        }
      }
    },
    // 图表颜色
    colors: {
      type: Array,
      default: () => {
        return ['#F3E141', '#00FBE7', '#00C4E1', '#09E8F8', '#7CFDAB', '#D0EC21']
      }
    },
    // 设置饼图大小
    radius: {
      type: [String, Array],
      default: () => {
        return [30, 90]
      }
    },
    // 数值单位
    unit: {
      type: String,
      default: '万吨'
    },
    // 类型
    roseType: {
      type: String,
      default: ''
    },
  },
  data () {
    return {
      chart: null,
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.initChart()
    })
  },
  beforeDestroy () {
    if (!this.chart) {
      return
    }
    this.chart.dispose()
    this.chart = null
  },
  methods: {
    initChart () {
      this.chart = echarts.init(this.$el, 'macarons')
      const _this = this

      // 定义自动轮播的数据项和索引
      const data = this.chartsData.data
      let currentIndex = 0

      this.chart.setOption({
        tooltip: {
          trigger: 'item',
          formatter: function (params) {
            var result = params.marker + params.name + ': ' + params.value + `${_this.unit}<br>`
            return result
          }
        },
        legend: {
          bottom: '16',
          data: _this.chartsData.legend
        },
        color: _this.colors,
        series: {
          type: 'pie',
          radius: _this.radius,
          data: _this.chartsData.data,
          // 设置渐变色
          itemStyle: {
            // normal: {
            //   color: function (params) {
            //     // 根据数据索引设置不同的渐变色
            //     const colorList = [
            //       new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            //         { offset: 0, color: '#F3E141' },
            //         { offset: 1, color: '#F3AE41' }
            //       ]),
            //       new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            //         { offset: 0, color: '#00E5FF' },
            //         { offset: 1, color: '#0089E1' }
            //       ]),
            //       new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            //         { offset: 0, color: '#00FBE7' },
            //         { offset: 1, color: '#00FBAE' }
            //       ]),
            //     ]
            //     return colorList[params.dataIndex]
            //   }
            // },
            color: (params) => {
              var index = params.dataIndex
              return this.colors[index]
            },
            borderWidth: 10
          },
          roseType: _this.roseType,
          label: {
            show: true,
            formatter: function (param) {
              return '{name|' + param.name + '}\n{value|' + param.value + '万吨}  {percent|' + param.percent + '%}'
            },
            rich: {
              name: {
                fontSize: 12,
                fontWeight: 'bold',
                lineHeight: 20,
                color: '#fff'
              },
              value: {
                fontSize: 14,
                fontWeight: 'bold',
                lineHeight: 20,
              },
              percent: {
                fontSize: 12,
                fontWeight: 'bold',
                lineHeight: 20,
                color: '#fff'
              },
            }
          },
        }
      })
      // 自动轮播tooltip和鼠标悬停效果
      setInterval(() => {
        // 切换到下一个数据项
        currentIndex = (currentIndex + 1) % data.length

        // 高亮当前数据项
        this.chart?.dispatchAction({
          type: 'highlight',
          seriesIndex: 0,
          dataIndex: currentIndex
        })

        // 显示当前数据项的tooltip
        this.chart?.dispatchAction({
          type: 'showTip',
          seriesIndex: 0,
          dataIndex: currentIndex
        })

        // 延时一段时间后取消高亮和隐藏tooltip
        setTimeout(() => {
          this.chart?.dispatchAction({
            type: 'downplay',
            seriesIndex: 0,
            dataIndex: currentIndex
          })
          this.chart?.dispatchAction({
            type: 'hideTip'
          })
        }, 2500) // 延时2秒后取消高亮和隐藏tooltip
      }, 3000) // 每3秒切换一次数据项
    }
  }
}
</script>