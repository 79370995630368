import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    index: 0,
    type: 1,
  },
  getters: {
  },
  mutations: {
    GetType(state, data) {
      console.log(data);
      state.type = data
    },
    MGetIndex(state, data) {
      if (data == 0) {
        state.index = 0
      } else {
        if (state.index >= 5) {
          state.index = 0
        } else {
          state.index = state.index + 1
        }
      }
    },
    MGetIndex1(state) {
      if (state.index >= 11) {
        state.index = 0
      } else {
        state.index = state.index + 1
      }
    }
  },
  actions: {
  },
  modules: {
  }
})
