<template>
  <div ref="page4" class="page4">
    <video ref="videoPlayer" name="media" autoplay controls loop>
      <source
        src="https://gp-hk-dev.oss-cn-hongkong.aliyuncs.com/%E4%BA%BF%E9%93%BE%E7%BA%B5%E6%A8%AA%E5%8F%8C%E7%A2%B3%E5%A4%A7%E5%B1%8F.mp4"
        type="video/mp4"
      />
    </video>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // autoplay: true
    };
  },
  mounted() {
    // this.$nextTick(() => {
    //     this.$refs.videoPlayer.play(); // 在页面加载完成后自动播放视频
    // });
  },
  methods: {
    startVideo() {
      // 当用户点击按钮后，触发视频播放
      this.$nextTick(() => {
        // this.autoplay = true;
        this.$refs.videoPlayer.play();
      });
    },
    pauseVideo() {
      // 当用户点击按钮后，触发视频播放
      this.$nextTick(() => {
        // this.autoplay = false;
        this.$refs.videoPlayer.pause();
      });
    },
  },
  beforeDestroy() {
    //     this.autoplay = false;
    // 组件销毁前确保视频停止加载和播放
    if (this.$refs.videoPlayer) {
      this.$refs.videoPlayer.pause();
      //         this.$refs.videoPlayer.removeAttribute('src'); // 删除src属性，停止视频加载
      //         this.$refs.videoPlayer.load();
    }
  },
};
</script>

<style lang="scss" scoped>
video {
  width: 3360px;
  height: 995.55px;
}
</style>